import { Button, Box, Typography } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export interface SmaabiotopProperties {
    geometry?: any;
    Areal?: string;
    Mark?: string;
    Intensiv?: string;
    markblokNr?: string;
    bioscore?: number;
    biotopId?: number;
    "ikke-nullet bioscore"?: string;
    domKomId?: string;
    domKomProp?: string;
    totKomProp?: string;
    TraeGrp?: string;
}

export interface SmaabiotopInfoboxProps {
    properties?: SmaabiotopProperties
    onClose: () => void
}

export interface LabelProps {
    label: string;
    value?: string | number | boolean | null
}

const Label = ({ label, value } : LabelProps) => {
    if (value === null || typeof (value) === 'undefined') {
        return null
    } else {
        return  <Typography>{label}: {value}</Typography>
    }
}

const SmaabiotopInfobox = ({ properties, onClose } : SmaabiotopInfoboxProps) => {
    const p = properties || {}
    if (p) {
        delete p.geometry
    }
    return (
        <Dialog onClose={() => onClose()} open={Boolean(properties)}>
            <DialogTitle>BiotopId: {p.biotopId}</DialogTitle>
            <Box sx={{ paddingTop: 0, paddingLeft: 2, paddingRight: 2, paddingBottom: 2}}>
                <Label label='Areal' value={p.Areal} />
                <Label label='Mark' value={p.Intensiv} />
                <Label label='Bioscore' value={p.bioscore} />            
                <Label label='Ikke-nullet bioscore' value={p["ikke-nullet bioscore"]} />
                <Label label='Markblok nr' value={p["markblokNr"]} />
                <Button size='small' variant='contained' color='inherit' fullWidth onClick={() => onClose()}>Luk</Button>
            </Box>
        </Dialog>
    )    
}

export default SmaabiotopInfobox